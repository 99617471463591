.card {
    width: 330px;
    text-align: center;
    cursor: pointer;
    border: none;
}

/* modal css */
.thumbnail {
    width: 15%;
    padding-bottom: 1rem;
}

.modal-header h1 {
    padding-bottom: 1rem;
}

.agentSummaryList li, p {
    font-size: 1.25em;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}