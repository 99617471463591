.mortgageCalculatorContainer {
    margin: 12rem 1rem 2.5rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.calcHeaders {
    padding-bottom: 2.5rem;
    text-align: center;
}

.calcHeaders h2 {
    color: black;
    padding-bottom: 40px;
}

.calcHeaders h3, .disclaimer h5 {
    color: #7E7D32;
}

.calcLabels {
    font-weight: bold;
}

.formPlusChartDiv {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1238px) {
        flex-direction: column;
    }
}

.formPlusChartDiv form {
    font-size: 1.25em;
    @media (max-width: 820px) {
        width: 75%;
        font-size: 1em;
    }
    @media (max-width: 400px) {
        width: 50%;
    }
}

.resultsDiv {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.resultsDiv h3 h4 {
    color: black;
}

.mortgageResult {
    font-size: 5em;
    color: cornflowerblue;

    @media (max-width: 820px) {
        font-size: 4em;
    }    
}

.totalResult {
    font-size: 2rem;
    color: green;
}

.lowerFormMC {
    display: flex;
    gap: 30px;
}

.disclaimer {
    margin-top: 40px;
    text-align: center;
    width: 50%;
    font-size: .75em;

    @media (max-width: 1225px) {
        width: 90%;
    }
}

.formInputs {
    text-align: left;
    font-size: 1.25em;
}