.buySellContainer {
    margin: 12rem 2rem 0rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.buySellContainer h3 {
    color: #7E7D32;
    padding-top: 40px;

    @media (max-width: 771px) {
        font-size: 1.3em;
    }
    @media (max-width: 644px) {
        font-size: 1.2em;
    }
}

.buySellContainer h2 {
    text-align: center;
    color:  #3B2313;

    @media (max-width: 771px) {
        font-size: 1.6em;
    }
    @media (max-width: 644px) {
        font-size: 1.4em;
    }
}

.reasonsToList {
    width: 80%;
    display: flex;
    flex-direction: column;
    font-size: 1.5em;
    list-style-type: none;
    position: relative;

    @media (max-width: 903px) {
        font-size: 1.5em;
    }
    @media (max-width: 772px) {
        font-size: 1.25em;
        width: 100%;
    }
}

.reasonsToList h3 {
    text-align: center;
}

.reasonsToList li {
    list-style-type: none;
    padding-bottom: 10px;
}

.littleIcons {
    margin-right: 20px;
    font-size: 3rem;
    color: #B2C035;
    stroke: #3B2313;
	stroke-width: 7.5;

    @media (max-width: 771px) {
        font-size: 2em;
    }
}

.littleIcons :hover {
    color: #7E7D32;
}

.asBuyerSellerList h3 {
    padding-bottom: 1rem;
    font-size: 1.5em;
}

.asBuyerSellerList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0rem 5rem 0rem 5rem;

    @media (max-width: 912px) {
        padding: 0rem 0rem 0rem 0rem;
        text-align: center;
    }
}

.asBuyerSellerList ul {
    font-size: 1.5em;
    text-align: left;
}

.asBuyerSellerList li {
    padding-bottom: 1rem;
    font-style: italic;
}

.buyerSellerDiv {
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
    
    @media (max-width: 772px) {
        flex-direction: column;
    }
}

#blackWhiteImg {
    width: 45%;

    @media (max-width: 820px) {
        width: 75%;
    }
}
