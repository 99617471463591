.hoaBackground {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.hoaBackground::before {
    content: '';
    background-image: url('../../images/plantBg.png');
    background-repeat: repeat;
    background-size: cover;
    background-position: top;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: .15;
}

.hoaInfoContainer {
    margin: 12rem 1rem 2rem 0rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hoaInfoContainer h2 {
    color:  #3B2313;
    text-align: center;
}

.hoaInfoContainer h3 {
    padding-top: 20px;
    color: #7E7D32;
    text-align: center;
}

.whatToKnowListDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.whatToKnowListDiv ul {
    width: 70%;

    @media (max-width: 1091px) {
        width: 90%;
    }
    @media (max-width: 632px) {
        width: 100%;
    }
}

.whatToKnowListDiv li {
    list-style-type: none;
    padding: 20px;
    font-size: 1.5em;
}
