/* Base Styles */
* {
    margin: 0;
    padding: 0;
    border: 0;
    zoom: 97%;
  }

a {
  text-decoration: none;
}

/* Base */
@import "base/typography.css";

/* Components */
@import "components/hero.css";
@import "components/cards.css";

/* Layouts */
@import "layouts/navigationBar.css";
@import "layouts/footer.css";

/* Pages */
@import "pages/agents.css";
@import "pages/about.css";
@import "pages/buysell.css";
@import "pages/specials.css";
@import "pages/rentwithus.css";
@import "pages/mortgagecalculator.css";
@import "pages/hoainfo.css";
@import "pages/searchresults.css";
@import "pages/associationslist.css";