.specialsContainer {
    margin: 12rem 1.25rem 2rem 0rem;
    text-align: center;
}

.specialsContainer h3 {
    color: #7E7D32;
    margin-top: 1rem;
}

.specialsInfoDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.5rem;
    padding-top: 20px;
}

.specialsInfoDiv li {
    padding-bottom: 20px;
}

.specialsInfoDiv img {
    width: 20%;
    padding-right: 20px;
    margin-bottom: 1rem;

    @media (max-width: 500px) {
        width: 40%;
    }
}