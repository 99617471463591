.rentWithUsBackground {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.rentWithUsBackground:before {
    content: '';
    background-image: url('../../images/plantBg.png');
    background-repeat: repeat;
    background-size: cover;
    background-position: top;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: .15;
}

.rentWithUsContainer {
    margin: 12rem 15rem 8rem 15rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;

    @media (max-width: 1224px) {
        margin: 12rem 0rem 1rem -1rem;
        font-size: 1em;
    }
    @media (max-width: 431px) {
        font-size: 0.75em;
    }
}

.rentWithUsContainer h2 {
    color:  #3B2313;
}

.rentWithUsContainer h3 {
    padding-top: 40px;
    padding-bottom: 20px;
    color: #7E7D32;
}

.rentWithUsContainer li {
    list-style: none;
    font-size: 1.5em;
    padding: 10px;
}