.hero {
    padding-top: 157px;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (max-width: 386px) {
        margin-top: 10px;
    }
}

.heroHeaders {
    color: #FAFAFA;
    text-shadow: 10px 5px 10px black;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    position: absolute;
    z-index: 1;
    text-align: center;
    margin-top: 30rem;
    font-weight: bold;

    @media (max-width: 1800px) {
        margin-top: 20rem;
    }
    @media (max-width: 1199px) {
        margin-top: 7rem;
    }
    @media (max-width: 340px) {
        visibility: hidden;
    }
}

.heroHeaders h1 {
    font-size: 4em;
    padding-bottom: 40px;
    font-weight: 900;

    @media (max-width: 1199px) {
        font-size: 3em;
    }
    @media (max-width: 600px) {
        font-size: 2em;
    }
}

.hero-listings-bar {
    width: 100%;
    position: fixed;
    z-index: 1;
    background-color: rgba(8, 12, 9, 0.6);
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 279px) {
        display: none;
    }
}
  
.hero-carousel {
    width: 100%;
    position: relative;
    z-index: 0;
}

.searchButton {
    font-weight: 700;
    font-size: 1.5em;
    color: white; 
    background-color: rgb(178,192,53);
    border: none;

    @media (max-width:820px) {
        width: 100%;
    }
    @media (max-width: 381px) {
        font-size: 1.2em;
    }
    @media (max-width: 308px) {
        font-size: 1em;
    }
}

.searchButton:hover {
    color: rgb(178,192,53); 
    background-color: white;
}
.searchButton:focus {
    color: rgb(178,192,53); 
    background-color: white !important;
}


.arrows-right {
    position: absolute;
    margin-left: 35%;
    transform: translate(-50%, -50%);
    transform: rotate(90deg);
    cursor: pointer;

    @media (max-width: 1488px) {
        margin-left: 42%;
    }
    @media (max-width: 1160px) {
        margin-left: 55%;
    }
    @media (max-width: 910px) {
        display: none;
    }
}

.arrows-right span {
    display: block;
    width: 1.5vw;
    height: 1.5vw;
    border-bottom: 5px solid white;
    border-right: 5px solid white;
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;

    @media (max-width: 1488px) {
        width: 1.75vw;
        height: 1.75vw;    
    }
}

.arrows-right span:nth-child(2) {
    animation-delay: -0.2s;
}

.arrows-right span:nth-child(3) {
    animation-delay: -0.4s;
}

@keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
}

.arrows-left {
    position: absolute;
    margin-right: 35%;
    transform: translate(-50%, -50%);
    transform: rotate(90deg);
    cursor: pointer;

    @media (max-width: 1488px) {
        margin-right: 42%;
    }
    @media (max-width: 1160px) {
        margin-right: 55%;
    }
    @media (max-width: 910px) {
        display: none;
    }
}


.arrows-left span {
    display: block;
    width: 1.5vw;
    height: 1.5vw;
    border-top: 5px solid white;
    border-left: 5px solid white;
    transform: rotate(45deg);
    margin: -10px;
    animation: animate2 2s infinite;
    animation-direction: reverse;

    @media (max-width: 1488px) {
        width: 1.75vw;
        height: 1.75vw;    
    }
    @media (max-width: 1160px) {
        width: 2vw;
        height: 2vw;    
    }
}

.arrows-left span:nth-child(3) {
    animation-delay: -0.1s;
}

.arrows-left span:nth-child(2) {
    animation-delay: -0.2s;
}

.arrows-left span:nth-child(1) {
    animation-delay: -0.4s;
}

@keyframes animate2 {
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
    50% {
        opacity: 1;
    }
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }
}
