.aboutContainer {
    margin: 12rem 1rem 2rem 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.aboutContainer h3 {
    color: #7E7D32;
    padding-bottom: 30px;
}

.aboutContainer h2 {
    color: black;
    padding-bottom: 10px;
}

.aboutContainer h4 {
    color: #7E7D32;

    @media (max-width: 700px) {
        padding-top: 20px;
    }
}

.aboutContainer p {
    font-size: 1.5em;

    @media (max-width: 1100px) {
        font-size: 1.1em;
    }
}

.about1stSection {
    width: 80%;
    text-align: left;
    display: flex;

    @media (max-width: 1500px) {
        width: 100%;
    }
    @media (max-width: 1000px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

.about1stSectionText {
    margin-left: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    @media (max-width: 1000px) {
        padding-top: 1rem;
        margin-left: 0rem;
    }
}

.about2ndSection {
    width: 80%;
    text-align: right;
    display: flex;
    padding-top: 3rem;

    @media (max-width: 1500px) {
        width: 100%;
    }
    @media (max-width: 1000px) {
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
        padding-top: 1rem;
    }
}

.about2ndSectionText {
    margin-right: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    @media (max-width: 1000px) {
        margin-right: 0rem;
        padding-top: 1rem;
    }
}

.gallery {
    --n: 4; /* number of rows*/
    --m: 4; /* number of columns */
    --h: 10rem; /* control the height */
    --w: 55rem; /* control the width */
    --g: .5rem;  /* control the gap */
    @media (max-width: 1000px) {
        --h: 12rem;
        --w: 15rem;
    }
    @media (max-width: 832px) {
        --h: 10rem;
        --w: 12rem;
    }
    @media (max-width: 676px) {
        --h: 7rem;
        --w: 9rem;
        --g: .25rem;
    }
    @media (max-width: 500px) {
        --h: 6rem;
        --w: 7rem;
    }

    display: grid;
    gap: var(--g);
    width:  calc(var(--m)*var(--w) + (var(--m) - 1)*var(--g));
    height: calc(var(--n)*var(--h) + (var(--n) - 1)*var(--g));
    grid-template-columns: repeat(var(--m),auto);
}

.gallery img {
    width: 0;
    height: 0;
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
    cursor: pointer;
    transition: .5s linear;
}
  
.gallery img:hover{
    @media (min-width: 1000px) {
        width:  calc(var(--w)*.6);
        height: calc(var(--h)*3);
    }
}

.gallery2 {
    --n: 2; /* number of rows*/
    --m: 2; /* number of columns */
    --h: 25rem; /* control the height */
    --w: 200rem; /* control the width */
    --g: 1rem;  /* control the gap */
    @media (max-width: 1000px) {
        --h: 21rem;
        --w: 26rem;
        --g: .5rem;
    }
    @media (max-width: 712px) {
        --h: 17rem;
        --w: 20rem;
        --g: .25rem;
    }
    @media (max-width: 546px) {
        --h: 15rem;
        --w: 17rem;
    }
    @media (max-width: 460px) {
        --h: 12rem;
        --w: 12rem;
    }

    display: grid;
    gap: var(--g);
    width:  calc(var(--m)*var(--w) + (var(--m) - 1)*var(--g));
    height: calc(var(--n)*var(--h) + (var(--n) - 1)*var(--g));
    grid-template-columns: repeat(var(--m),auto);
}

.gallery2 img {
    width: 0;
    height: 0;
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
    cursor: pointer;
    transition: .5s linear;
}

.gallery2 img:hover{
    @media (min-width: 1000px) {
        width:  calc(var(--w)*.3);
        height: calc(var(--h)*2);
    }
}