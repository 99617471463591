.footer {
  background-color: black;
  padding-top: 50px;
  color: #FAFAFA;
}

.footer .list-group-item {
  background-color: transparent;
  border: none;
  color: #FAFAFA;
}

.contactCol {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 1rem;
  margin-bottom: 2rem;

  @media (max-width: 440px) {
    gap: 0;
  }
}

.social-icon {
  text-align: center;
  font-size: 3.5rem;
  color: #FAFAFA;
  background-color: black;
}

.social-icon:hover {
  color: #B2C035;
}

.contactForm input {
  border: 1px solid black;
  width: 100%;
}

.contactForm textarea {
  border: 1px solid black;
  margin-bottom: 10px;
  width: 100%;
  height: 200px;
}

.footer-link-heading h5 {
  color: #7E7D32;
}

.footer-link-heading a {
  color: #FAFAFA;
}

.location iframe {
  @media (max-width: 440px) {
    width: 275px;
  }
}

.adminLink a {
  color: #B2C035;
}

.adminLink a:hover {
  text-decoration: underline;
}

.webDevLink {
  font-size: 0.8em;
}

.webDevLink a {
  color: white;
}

.webDevLink a:hover {
  text-decoration: underline;
}