.bg-body-tertiary {
    background-color: #FFFF !important;
}

@media (max-width: 991px) {
    .navbar-collapse {
        position: fixed;
        top: 7.73rem;
        right: -250px;
        width: 250px;
        height: 100%;
        max-height: calc(100% - 5.73rem);
        overflow-y: auto;
        background-color: white;
        transition: right 0.3s ease-in;
        text-align: center;
        padding-top: 4.5rem;
        padding-bottom: 2rem;
    }
    .navbar-collapse.show {
        right: 0;
    }
}  

.navDropdownItem {
    padding: 1rem;
}

.navDropdownItem:hover {
    background-color: rgb(59,35,19, .25);
}

.navbar img {
    @media (max-width: 575px) {
        width: 80%;
    }
}
