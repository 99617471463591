.wrapper {
	padding: 2rem 0rem 0rem 0rem;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	border-top: 1px solid black;

	@media (max-width: 960px) {
		display: none;
	}
}

.cardIcon {
	border: none;
	width: 10rem;
	margin-left: 1rem;
	margin-right: 1rem;
	text-align: center;
	cursor: pointer;
	padding-bottom: 1.5rem;
	}

.cardIcon h5 {
	color: black;
	padding-top: 10px;
}

.icons {
	font-size: 4.5rem;
	color: #B2C035;
	stroke: #3B2313;
	stroke-width: 7.5;
}

.icons:hover {
	animation: bounce 1.3s ease-out;
	color: #7E7D32;
}

@keyframes bounce {
	0%,
	20%,
	60%,
	100% {
		transform: translateY(0);
	}
	40% {
		transform: translateY(-30px)
	}
	80% {
		transform: translateY(-20px)
	}
}