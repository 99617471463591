.associationsContainer h1 {
    color: #3B2313;
}
.associationsButtons {
    background-color: white;
    color: black;
}

.associationsButtons:hover {
    color: #B2C035;
    text-decoration: underline;
}